.archives-container {
  position: relative;
  padding-left: 15px;
}

.float-button {
  position: absolute;
  bottom: 20px;
  right: 10px;
  background-color: #3498db;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.float-button i {
  font-size: 24px;
}

.float-button.expanded {
  width: 300px;
  height: auto;
  border-radius: 10px;
}

.archives-content{
  margin-right: 5%;
    position: fixed;
  bottom: 70px;
  right: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px grey;
  display: none;
}

.archives-container .archives-content {
  display: block;
  margin-right: 3%;

}

.archives-content h2 {
  margin-top: 0;
}
