.posts-container {
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-right: 15px;
  }
  
  .posts-container .side-panel {
    width: 20%;
    height: 100%;
    background-color: #ebebeb;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: scroll;
  }
  
  .posts-container .posts-section {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
  }
  
  .posts-container .posts-section .post {
    width: 80%;
    margin: 20px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    overflow: hidden;
  }
  