
.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    /* margin: 20px 0; */
    margin-left: 20%;
    padding-right: 250px;

  }
  
  .pagination li {
    display: inline-block;
    padding: 10px;
    border: 1px solid gray;
    cursor: pointer;
    margin-right: 10px;
    
  }
  
  .pagination .active {
    background-color: gray;
    color: white;
  }
  
  @media (max-width: 767px) {
    .pagination {
      
      margin-left: 50%;
       
  
    }
  }