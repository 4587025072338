.formdiv{
    margin-top: 10%;
margin-left: 10%;
margin-right: 10%;
margin-bottom: 0px;
padding-bottom: 100px;
}

#your-form-container-id {
    width: 100%;
  
    @media (min-width: 576px) {
      width: 100%;
    }
  }