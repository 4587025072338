.post {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    margin-left: 10%;
    margin-right: 10%;
  }
  
  .post-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .post-header .post-title {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    color: cadetblue;
  }
  
  .post-header .post-author {
    font-size: 14px;
    font-weight: bold;
    color: #aaa;
    margin: 0;
  }
  
  .post-body {
    width: 100%;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
  }
  
  .post-footer {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .post-footer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
  
  /* For large screens */
@media (min-width: 768px) {
  .post-title {
    display: inline-block;
  }
  .post-author {
    display: inline-block;
    margin-left: 20px;
  }
}

/* For mobile screens */
@media (max-width: 767px) {
  .post-title {
    display: block;
  }
  .post-author {
    display: block;
    margin-top: 10px;
    margin-left: 10px;
  }
}